@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

* {
    font-family: 'Mulish', sans-serif;
}

body {
    position: relative;
    /*min-width: 420px;*/
}

.content {
    outline: none;
}

section {
    padding-top: 3rem;
    margin-bottom: 3rem;
}

.section-title {
    border-bottom: #6e6e6e 1px solid;
    padding-bottom: 1rem;
    width: 10rem;
}

hr.separator {
    border: 0;
    height: 1px;
}

.nav-link {
    color: #000 !important;
}

