.menu .nav-pills .nav-link.active {
    border: #000 1px solid;
    background: transparent;
    color: #000;
}

.item-price {
    margin-left: auto;
}

.accordion-button::after {
    margin-left: 1rem;
}