.main-section {
    background: url(../../img/main_img.jpeg) no-repeat center;
    background-size: cover;
    height: 100vh;
}

.main-section .book-table-button {
    display: none;
    background: rgba(0, 0, 0, 0.5);
    font-size: 1.2rem;
}

.main-section .contact-info {
    background: rgba(0, 0, 0, 0.7);
    opacity: 1;
    transition: opacity 500ms;
}

@media only screen and (max-width: 991px) {
    .main-section .book-table-button {
        display: block;
    }

    .main-section .contact-info {
        display: none;
    }
}