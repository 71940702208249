.language {
    outline: none;
    caret-color: transparent !important
}

.language button {
    color: #6c757d!important;
    outline: none !important;
    text-decoration: none;
    border: none;
    background: none !important;
}

.language button:hover, .language button.active {
    cursor: pointer;
    color: #212529!important;
}