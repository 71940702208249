.navbar {
    background: rgba(255, 255, 255, 0.7) !important;
    caret-color: transparent !important;
}

.navbar .navbar-collapse {
    text-align: center;
}

.navbar .book-table-button {
    display: none;
}

@media only screen and (min-width: 992px) {
    .navbar .book-table-button {
        display: block;
    }
}

@media only screen and (max-width: 991px) {
    .navbar .disabled {
        display: none;
    }
}

.book-table-modal .modal-body {
    background-color: transparent;
}
.book-table-modal iframe {
    min-height: 600px;
}

.menu-pdf-download {
    text-decoration: none;
}