@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
* {
    font-family: 'Mulish', sans-serif;
}

body {
    position: relative;
    /*min-width: 420px;*/
}

.content {
    outline: none;
}

section {
    padding-top: 3rem;
    margin-bottom: 3rem;
}

.section-title {
    border-bottom: #6e6e6e 1px solid;
    padding-bottom: 1rem;
    width: 10rem;
}

hr.separator {
    border: 0;
    height: 1px;
}

.nav-link {
    color: #000 !important;
}



.navbar {
    background: rgba(255, 255, 255, 0.7) !important;
    caret-color: transparent !important;
}

.navbar .navbar-collapse {
    text-align: center;
}

.navbar .book-table-button {
    display: none;
}

@media only screen and (min-width: 992px) {
    .navbar .book-table-button {
        display: block;
    }
}

@media only screen and (max-width: 991px) {
    .navbar .disabled {
        display: none;
    }
}

.book-table-modal .modal-body {
    background-color: transparent;
}
.book-table-modal iframe {
    min-height: 600px;
}

.menu-pdf-download {
    text-decoration: none;
}
.language {
    outline: none;
    caret-color: transparent !important
}

.language button {
    color: #6c757d!important;
    outline: none !important;
    text-decoration: none;
    border: none;
    background: none !important;
}

.language button:hover, .language button.active {
    cursor: pointer;
    color: #212529!important;
}
.main-section {
    background: url(/static/media/main_img.b5382ad8.jpeg) no-repeat center;
    background-size: cover;
    height: 100vh;
}

.main-section .book-table-button {
    display: none;
    background: rgba(0, 0, 0, 0.5);
    font-size: 1.2rem;
}

.main-section .contact-info {
    background: rgba(0, 0, 0, 0.7);
    opacity: 1;
    transition: opacity 500ms;
}

@media only screen and (max-width: 991px) {
    .main-section .book-table-button {
        display: block;
    }

    .main-section .contact-info {
        display: none;
    }
}
.contact a {
    color: rgba(255, 255, 255, 1) !important;
    text-decoration: none;
}

.contact a:hover {
    color: rgba(255, 255, 255, 0.9) !important;
}

.contact .open-hours {
    white-space: pre-line;
}


.menu .nav-pills .nav-link.active {
    border: #000 1px solid;
    background: transparent;
    color: #000;
}

.item-price {
    margin-left: auto;
}

.accordion-button::after {
    margin-left: 1rem;
}
.contact svg {
    margin-right: 0.5rem;
}

.contact .social svg {
    font-size: 2rem;
}

.contact .social svg:first-child {
    margin-left: 0.7rem;
}
