.contact svg {
    margin-right: 0.5rem;
}

.contact .social svg {
    font-size: 2rem;
}

.contact .social svg:first-child {
    margin-left: 0.7rem;
}