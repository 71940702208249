.contact a {
    color: rgba(255, 255, 255, 1) !important;
    text-decoration: none;
}

.contact a:hover {
    color: rgba(255, 255, 255, 0.9) !important;
}

.contact .open-hours {
    white-space: pre-line;
}

